<template>
  <section :class="['hero', { 'hero--alt': alt }]">
    <Header />
    <Navigation />
    <section class="hero__heading">
      <h1 v-if="!alt" class="heading">{{ heading }}</h1>
      <h1 v-else class="heading heading--big">{{ heading }}</h1>
    </section>
    <div ref="background" class="hero__background"></div>
  </section>
</template>

<script>
import Header from "@/components/Header";
import Navigation from "@/components/Navigation";
import HomeBG from "@/assets/images/Home/hero.jpg";
import VergaderenBG from "@/assets/images/Vergaderen/hero.jpg";
import VergaderzalenBG from "@/assets/images/Vergaderzalen/hero.jpg";
import CongresBG from "@/assets/images/Congres/hero.jpg";
import ForumBG from "@/assets/images/Forum/hero.jpg";
import TheaterBG from "@/assets/images/Theater/hero.jpg";
import PrivateDiningBG from "@/assets/images/Dining/hero.jpg";
import OnzeRuimtesBG from "@/assets/images/OnzeRuimtes/hero.jpg";
import ContactBG from "@/assets/images/Contact/hero.jpg";
import EtenBG from "@/assets/images/Eten/hero.jpg";
import UwEventBG from "@/assets/images/UwEvent/hero.jpg";
import PresenterenBG from "@/assets/images/Presenteren/hero.jpg";
import ActiviteitenBG from "@/assets/images/Activiteiten/hero.jpg";
import WriterAsGuestSpeakerBG from "@/assets/images/WriterAsGuestSpeaker/hero.jpg";

import Hall2BG from "@/assets/images/Hall-2/hero.jpg";
import Hall1BG from "@/assets/images/Hall-1/hero.jpg";
import Hall3BG from "@/assets/images/Hall-3/hero.jpg";
import Hall4BG from "@/assets/images/Hall-4/hero.jpg";
import Hall5BG from "@/assets/images/Hall-5/hero.jpg";
import Hall6BG from "@/assets/images/Hall-6/hero.jpg";
import Hall4_1BG from "@/assets/images/Hall-4_1/hero.jpg";
import Hall4_2BG from "@/assets/images/Hall-4_2/hero.jpg";
import Hall7BG from "@/assets/images/Hall-7/hero.jpg";
import Hall8BG from "@/assets/images/Hall-8/hero.jpg";
import Hall9BG from "@/assets/images/Hall-9/hero.jpg";
import Hall10BG from "@/assets/images/Hall-10/hero.jpg";

export default {
  name: "Hero",

  components: {
    Header,
    Navigation
  },

  props: ["alt", "heading"],

  mounted() {
    switch (this.alt) {
      case "vergaderen":
        this.$refs.background.style.backgroundImage = `url('${VergaderenBG}')`;
        this.$el.style.backgroundImage = `url('${VergaderenBG}')`;
        break;
      case "vergaderzalen":
        this.$refs.background.style.backgroundImage = `url('${VergaderzalenBG}')`;
        this.$el.style.backgroundImage = `url('${VergaderzalenBG}')`;
        break;
      case "congres":
        this.$refs.background.style.backgroundImage = `url('${CongresBG}')`;
        this.$el.style.backgroundImage = `url('${CongresBG}')`;
        break;
      case "forum":
        this.$refs.background.style.backgroundImage = `url('${ForumBG}')`;
        this.$el.style.backgroundImage = `url('${ForumBG}')`;
        break;
      case "Theater":
        this.$refs.background.style.backgroundImage = `url('${TheaterBG}')`;
        this.$el.style.backgroundImage = `url('${TheaterBG}')`;
        break;
      case "onzeRuimtes":
        this.$refs.background.style.backgroundImage = `url('${OnzeRuimtesBG}')`;
        this.$el.style.backgroundImage = `url('${OnzeRuimtesBG}')`;
        break;
      case "privateDining":
        this.$refs.background.style.backgroundImage = `url('${PrivateDiningBG}')`;
        this.$el.style.backgroundImage = `url('${PrivateDiningBG}')`;
        break;
      case "WriterAsGuestSpeaker":
        this.$refs.background.style.backgroundImage = `url('${WriterAsGuestSpeakerBG}')`;
        this.$el.style.backgroundImage = `url('${WriterAsGuestSpeakerBG}')`;
        break;
      case "presenteren":
        this.$refs.background.style.backgroundImage = `url('${PresenterenBG}')`;
        this.$el.style.backgroundImage = `url('${PresenterenBG}')`;
        break;
      case "activiteiten":
        this.$refs.background.style.backgroundImage = `url('${ActiviteitenBG}')`;
        this.$el.style.backgroundImage = `url('${ActiviteitenBG}')`;
        break;
      case "eten":
        this.$refs.background.style.backgroundImage = `url('${EtenBG}')`;
        this.$el.style.backgroundImage = `url('${EtenBG}')`;
        break;
      case "uwevent":
        this.$refs.background.style.backgroundImage = `url('${UwEventBG}')`;
        this.$el.style.backgroundImage = `url('${UwEventBG}')`;
        break;
      case "Hall-1":
        this.$refs.background.style.backgroundImage = `url('${Hall1BG}')`;
        this.$el.style.backgroundImage = `url('${Hall1BG}')`;
        break;
      case "Hall-2":
        this.$refs.background.style.backgroundImage = `url('${Hall2BG}')`;
        this.$el.style.backgroundImage = `url('${Hall2BG}')`;
        break;
      case "Hall-3":
        this.$refs.background.style.backgroundImage = `url('${Hall3BG}')`;
        this.$el.style.backgroundImage = `url('${Hall3BG}')`;
        break;
      case "Hall-4":
        this.$refs.background.style.backgroundImage = `url('${Hall4BG}')`;
        this.$el.style.backgroundImage = `url('${Hall4BG}')`;
        break;
      case "Hall-5":
        this.$refs.background.style.backgroundImage = `url('${Hall5BG}')`;
        this.$el.style.backgroundImage = `url('${Hall5BG}')`;
        break;
      case "Hall-6":
        this.$refs.background.style.backgroundImage = `url('${Hall6BG}')`;
        this.$el.style.backgroundImage = `url('${Hall6BG}')`;
        break;
      case "Hall-7":
        this.$refs.background.style.backgroundImage = `url('${Hall7BG}')`;
        this.$el.style.backgroundImage = `url('${Hall7BG}')`;
        break;
      case "Hall-8":
        this.$refs.background.style.backgroundImage = `url('${Hall8BG}')`;
        this.$el.style.backgroundImage = `url('${Hall8BG}')`;
        break;
      case "Hall-9":
        this.$refs.background.style.backgroundImage = `url('${Hall9BG}')`;
        this.$el.style.backgroundImage = `url('${Hall9BG}')`;
        break;
      case "Hall-10":
        this.$refs.background.style.backgroundImage = `url('${Hall10BG}')`;
        this.$el.style.backgroundImage = `url('${Hall10BG}')`;
        break;
      case "Hall-4.1":
        this.$refs.background.style.backgroundImage = `url('${Hall4_1BG}')`;
        this.$el.style.backgroundImage = `url('${Hall4_1BG}')`;
        break;
      case "Hall-4.2":
        this.$refs.background.style.backgroundImage = `url('${Hall4_2BG}')`;
        this.$el.style.backgroundImage = `url('${Hall4_2BG}')`;
        break;
      case "contact":
        this.$refs.background.style.backgroundImage = `url('${ContactBG}')`;
        this.$el.style.backgroundImage = `url('${ContactBG}')`;
        break;
      case "clean":
        this.$refs.background.style.backgroundImage = ``;
        this.$el.style.backgroundImage = ``;
        break;
      default:
        this.$refs.background.style.backgroundImage = `url('${HomeBG}')`;
        this.$el.style.backgroundImage = `url('${HomeBG}')`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/utils";

.hero {
  position: relative;
  width: 100vw;
  height: 360px;
  background-color: $warm-grey;
  background-size: cover;
  background-position: center center;

  &:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  }

  // media queries
  @include respond-to("s") {
    height: calc(80vh - 80px);
    overflow: hidden;

    &:before {
      display: none;
    }

    @media screen and (orientation: portrait) {
      height: calc(50vh);
    }
  }

  @include respond-to("m") {
    height: 100vh;
  }
}

.hero__background {
  display: none;
  width: inherit;
  height: inherit;
  background-color: $warm-grey;
  background-size: cover;
  background-position: center center;
  will-change: auto;
  animation: animate 100s linear infinite;

  &:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  }
}

.hero__heading {
  position: absolute;
  bottom: 40px;
  padding-left: 16px;
  z-index: 9;
}

.hero--alt {
  max-height: 600px;

  .hero__heading {
    bottom: 30px;
  }
}

// media queries
@include respond-to("s") {
  .hero__background {
    display: block;
    height: 100%;
  }

  .hero--alt {
    .hero__heading {
      bottom: 0;
    }
  }
}

@include respond-to("m") {
  .hero__heading {
    width: 100%;
    left: 50%;
    bottom: 200px;
    transform: translateX(-50%);
    max-width: $inner-max-width;
  }
}

@include respond-to("l") {
  .hero__heading {
    padding: 0;
  }
}

@keyframes animate {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.2) translateX(-5%);
  }

  40% {
    transform: scale(1.4) translateX(-10%);
  }

  60% {
    transform: scale(1.4) translateX(10%);
  }

  80% {
    transform: scale(1.2) translateX(5%);
  }
}
</style>
