<template>
  <transition name="fade">
    <div>
      <Hero alt="activiteiten" heading="activiteiten" />
      <Intro
        heading="activiteiten"
        image="activiteiten"
        copy="Wat meer dan honderd jaar geleden begon als de Openbare Bibliotheek van Amsterdam is vandaag bekend als de OBA; een ontmoetingsplek van én voor de stad en de best bezochte culturele instelling van de hoofdstad. Bent u op zoek naar een inspirerende activiteit voor uw team of een energiek intermezzo om uw formele bijeenkomst even mee te onderbreken? Wij doen u graag een passend voorstel."
      />
      <Slider :type="4" heading="uw evenement" :slides="slidesTop" />
      <Slider :type="6" heading="zalen" :slides="slidesBottom" />
      <Table heading="overzicht" :spaces="spaces" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo, meetingSpacesSlides, otherSpacesSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "Activiteiten",

  components: {
    Hero,
    Intro,
    Slider,
    Table,
    Form,
    Footer
  },

  data() {
    return {
      slidesTop: otherSpacesSlides,
      slidesBottom: meetingSpacesSlides,
      spaces: spacesInfo
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
