<template>
  <transition name="fade">
    <div>
      <Hero alt="congres" heading="congres & beurs" />
      <Intro
        heading="congres & beurs"
        image="congres"
        copy="Indien u een congres of beurs wil organiseren, maken wij graag een voorstel op maat voor u. Dankzij de verschillende vergaderruimtes en zalen, elk met eigen unieke kenmerken en faciliteiten, hebben we alle mogelijkheden om op uw wensen in te spelen. We helpen u graag."
      />
      <Slider :type="4" heading="uw evenement" :slides="slidesTop" />
      <Slider :type="6" heading="zalen" :slides="slidesBottom" />
      <Table heading="overzicht" :spaces="spaces.filter(space => space.size === 'medium')" />
      <Form id="contact" />
      <Footer />
    </div>
  </transition>
</template>

<script>
import Hero from "@/components/Hero.vue";
import Intro from "@/components/Intro.vue";
import Slider from "@/components/Slider.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import Footer from "@/components/Footer.vue";

import { spacesInfo, meetingSpacesSlides, otherSpacesSlides } from "@/data/spaces.js";

import AOS from "aos";

export default {
  name: "CongresBeurs",

  components: {
    Hero,
    Intro,
    Slider,
    Table,
    Form,
    Footer
  },

  data() {
    return {
      slidesTop: otherSpacesSlides,
      slidesBottom: meetingSpacesSlides,
      spaces: spacesInfo
    };
  },

  mounted() {
    window.scrollTo(0, 0);

    AOS.init({
      offset: 0,
      duration: 600,
      anchorPlacement: "top-bottom",
      easing: "cubic-bezier(0.77, 0, 0.175, 1)"
    });
  }
};
</script>
