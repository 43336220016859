<template>
    <transition name="fade">
      <div>
        <Hero alt="WriterAsGuestSpeaker" heading="Schrijver te gast" />
        <Gallery
          :type="0"
          :slides="gallery"
          heading="Breng extra diepgang en inspiratie naar uw bijeenkomst met een schrijver"
          info="Wilt u uw bijeenkomst verrijken met nieuwe perspectieven en inspirerende verhalen? Overweeg dan een samenwerking met een schrijver! Via het programma <span style='font-style: italic; font-weight: bold;'>Schrijvers over Thema’s</span>, in samenwerking met de Schrijverscentrale, bieden wij unieke mogelijkheden om uw event naar een hoger niveau te tillen.<br></br>
            Schrijvers uit dit programma zijn experts in het verwoorden van complexe thema’s en kunnen uw bijeenkomst op maat versterken. Of het nu gaat om maatschappelijke onderwerpen zoals klimaat, ongelijkheid, diversiteit of inclusie, zij brengen inzichten die aansluiten bij wat er speelt binnen uw organisatie.<br></br>
            Laat een schrijver uw dag voorzitten, een inspirerende lezing geven of afsluiten met een pakkende gesproken column die alles samenbrengt.<br></br>
            Meer weten? Bezoek de <a href='https://deschrijverscentrale.nl/schrijvers-over-themas' target='_blank'>website</a> en ontdek hoe een schrijver uw bijeenkomst kan verrijken."
        />
        <Form id="contact" />
        <Footer />
      </div>
    </transition>
  </template>
  
  <script>
  import Hero from "@/components/Hero.vue";
  import Gallery from "@/components/Gallery.vue";
  import Form from "@/components/Form.vue";
  import Footer from "@/components/Footer.vue";
  
  import AOS from "aos";
  
  export default {
    name: "WriterAsGuestSpeaker",
  
    components: {
      Hero,
      Gallery,
      Form,
      Footer
    },
  
    data() {
      return {
        gallery: []
      };
    },
  
    mounted() {
      window.scrollTo(0, 0);
  
      AOS.init({
        offset: 0,
        duration: 600,
        anchorPlacement: "top-bottom",
        easing: "cubic-bezier(0.77, 0, 0.175, 1)"
      });
    }
  };
  </script>
  
